import Image from "next/image";

import Heading from "@/components/heading/heading";
import Link from "next/link";

interface DestinationCardProps {
    picture: any;
    name: string;
    note?: string;
    path: string;
}

const DestinationCard = (props: DestinationCardProps) => {
    const { picture, name, note, path } = props;
    return (
        <Link href={path} className="inline-flex flex-col gap-6">
            <figure>
                <Image loading="lazy" src={picture} alt={name}  />
                <figcaption className="flex flex-col items-start justify-end p-4">
                    <Heading as="h2" className="font-yesteryear font-extralight flex-center">
                        {name}
                    </Heading>
                    {note ? (
                        <span className="font-light text-white">{note}</span>
                    ) : null}
                </figcaption>
            </figure>
        </Link>
    );
};

export default DestinationCard;
